import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import 'vite/modulepreload-polyfill';
import { retrieveLaunchParams, swipeBehavior, init } from '@telegram-apps/sdk';
import { setTelegramUser } from 'stores/api/index';
import { initSentry } from './sentry.ts';
import eruda from 'eruda';

if (localStorage.getItem('eruda_activated') === 'true') {
  eruda.init();
}

initSentry();

try {
  const launchParams = retrieveLaunchParams();
  setTelegramUser(launchParams);
} catch {
  // nothing
}

try {
  init();
  if (swipeBehavior.mount.isAvailable()) {
    swipeBehavior.mount();
    swipeBehavior.isMounted();
    swipeBehavior.disableVertical();
    swipeBehavior.isVerticalEnabled();
  }
} catch (err) {
  console.error(err);
}

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
