import { Button, ButtonTypeMap, ExtendButtonBase, styled } from '@mui/material';
import { BottomAppBar } from 'src/components/shell';
import BookIcon from '@mui/icons-material/Book';
// import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import PersonIcon from '@mui/icons-material/Person';
import paths from 'src/paths';
import { Link } from 'react-router-dom';
import { useCallback, useRef } from 'react';
import eruda from 'eruda';

const FooterButton = styled(Button)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}) as ExtendButtonBase<ButtonTypeMap>;

export const Footer = () => {
  const erudaTimerRef = useRef<NodeJS.Timeout>();
  const startTimer = useCallback(() => {
    if (erudaTimerRef.current) {
      clearTimeout(erudaTimerRef.current);
    }
    erudaTimerRef.current = setTimeout(() => {
      localStorage.setItem(
        'eruda_activated',
        localStorage.getItem('eruda_activated') === 'true' ? 'false' : 'true',
      );
      if (localStorage.getItem('eruda_activated') === 'true') {
        eruda.init();
      } else {
        eruda.destroy();
      }
    }, 10000);
  }, []);
  const endTimer = useCallback(() => {
    if (erudaTimerRef.current) {
      clearTimeout(erudaTimerRef.current);
    }
  }, []);
  return (
    <BottomAppBar sx={{ bgcolor: 'background.paper', height: 64 }}>
      <FooterButton variant="text" component={Link} to={paths.diary({})}>
        <BookIcon />
        Дневник
      </FooterButton>
      {/* <FooterButton variant="text" component={Link} to={paths.recipies({})}>
              <AutoStoriesIcon />
              Рецепты
            </FooterButton> */}
      <FooterButton
        variant="text"
        component={Link}
        to={paths.profile({})}
        onPointerDown={startTimer}
        onPointerUp={endTimer}
      >
        <PersonIcon />
        Профиль
      </FooterButton>
    </BottomAppBar>
  );
};
