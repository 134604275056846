import { createModelStore, createQuery } from 'mst-query';
import { createPaginated, PaginationFilters } from './utils';
import { types } from 'mobx-state-tree';
import { Recipe, RecipeFilters } from 'stores/models';
import { loadRecipies, loadRecipe } from 'stores/api';

export const RecipiesQuery = createQuery('RecipiesQuery', {
  data: createPaginated(types.reference(Recipe)),
  request: types.compose(RecipeFilters, PaginationFilters),
  endpoint({ signal, request }) {
    return loadRecipies(request, { signal });
  },
});

export const RecipeQuery = createQuery('RecipeQuery', {
  data: types.reference(Recipe),
  request: types.string,
  endpoint({ signal, request }) {
    return loadRecipe(request, { signal });
  },
});

export const RecipeStore = createModelStore('RecipeStore', Recipe).props({
  recipiesQuery: types.optional(RecipiesQuery, {}),
  recipeQuery: types.optional(RecipeQuery, {}),
});
