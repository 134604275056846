import { types, getSnapshot } from 'mobx-state-tree';
import { createModelStore, createQuery } from 'mst-query';
import { loadNutritionStatistics } from 'stores/api';
import { NutritionStatistics, StatisticsFilters } from 'stores/models';

export const StatisticsQuery = createQuery('StatisticsQuery', {
  data: NutritionStatistics,
  request: StatisticsFilters,
  endpoint({ request, signal }) {
    return loadNutritionStatistics(getSnapshot(request), { signal });
  },
});

export const StatisticsStore = createModelStore(
  'StatisticsStore',
  NutritionStatistics,
).props({
  statistcsQuery: types.optional(StatisticsQuery, {}),
});
