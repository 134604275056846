import { types } from 'mobx-state-tree';
import { createModelStore, createMutation } from 'mst-query';
import { saveNutrition } from 'stores/api';
import { Nutrition, SaveNutrition } from 'stores/models';

export const SaveNutritionMutation = createMutation('SaveNutritionMutation', {
  data: Nutrition,
  request: SaveNutrition,
  endpoint({ request }) {
    return saveNutrition(request);
  },
});

export const NutritionStore = createModelStore(
  'NutritionStore',
  Nutrition,
).props({
  saveNutritionMutation: types.optional(SaveNutritionMutation, {}),
});
