import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { Category } from './Category';
import { Unit } from './Unit';
import { sorterByOrder } from './Dictionary';

export const Dictionaries = types
  .model('Dictionaries', {
    categories: types.array(types.reference(Category)),
    units: types.array(types.reference(Unit)),
    // TODO: Заполненные дни для пользователя. Необходимо вынести для получения списка по месяцам.
    //   filledUTCDates: types.array(types.string),
  })
  .preProcessSnapshot((snapshot) => ({
    ...snapshot,
    categories: snapshot?.categories?.slice().sort(sorterByOrder as any),
    units: snapshot?.units?.slice().sort(sorterByOrder as any),
  }));

export type DictionariesInstance = Instance<typeof Dictionaries>;
export type DictionariesSnapshotIn = SnapshotIn<typeof Dictionaries>;
export type DictionariesSnapshotOut = SnapshotOut<typeof Dictionaries>;
