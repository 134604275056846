import {
  AppBar,
  AppBarProps,
  Slide,
  Toolbar,
  useScrollTrigger,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

export function HideOnScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export const TopAppBar: React.FC<PropsWithChildren & AppBarProps> = observer(
  ({ children, ...props }) => {
    return (
      <HideOnScroll>
        <AppBar position="sticky" {...props}>
          <Toolbar>{children}</Toolbar>
        </AppBar>
      </HideOnScroll>
    );
  },
);

export const BottomAppBar: React.FC<PropsWithChildren & AppBarProps> = observer(
  ({ children, ...props }) => {
    return (
      <AppBar
        component="footer"
        align="center"
        position="sticky"
        color="primary"
        {...props}
        sx={{ top: 'auto', bottom: 0, ...props.sx }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
          {children}
        </Toolbar>
      </AppBar>
    );
  },
);
