import { flow, getSnapshot, types } from 'mobx-state-tree';
import { createModelStore, createMutation, onMutate } from 'mst-query';
import {
  getTelegramLaunchParams,
  getUser,
  login,
  loginTgUser,
  register,
  setUser,
} from 'stores/api';
import {
  LoginRequest,
  LoginRequestInstance,
  TgLoginRequest,
  TgLoginRequestRequestInstance,
  User,
  UserInstance,
} from 'stores/models';
import { withSetPropAction } from 'stores/utils';

export const LoginUserMutation = createMutation('LoginUserMutation', {
  data: User,
  request: types.union(LoginRequest, TgLoginRequest),
  endpoint({ request }) {
    if ((request as TgLoginRequestRequestInstance).tg_init_data_raw) {
      return loginTgUser(getSnapshot(request as TgLoginRequestRequestInstance));
    }
    return login(getSnapshot(request as LoginRequestInstance));
  },
});

export const RegisterUserMutation = createMutation('RegisterUserMutation', {
  data: User,
  endpoint() {
    return register();
  },
});

export const UserStore = createModelStore('UserStore', User)
  .props({
    isWasAutoRegisteredNow: types.optional(types.boolean, false),
    isWasRegisteredNow: types.optional(types.boolean, false),
    loginUserMutation: types.optional(LoginUserMutation, {}),
    registerUserMutation: types.optional(RegisterUserMutation, {}),
    currentUser: types.maybe(User),
  })
  .actions(withSetPropAction)
  .actions((self) => ({
    restoreUser() {
      const user: UserInstance | null = getUser();
      if (user?.auth_token) {
        return user;
      }
    },
    async loginTg() {
      const launchParams = getTelegramLaunchParams();
      if (launchParams?.initDataRaw) {
        // Если зашли из телеграмма, то входим без регистрации. Регистрация происходит автоматически на сервере, если есть необходимость
        const { data, error } = await self.loginUserMutation.mutate({
          request: { tg_init_data_raw: launchParams?.initDataRaw },
        });
        if (!error) {
          return getSnapshot(data);
        }
      }
      return null;
    },
    async login() {
      const user = getUser();
      if (user?.username && user?.created_password) {
        await self.loginUserMutation.mutate({
          request: { username: user.username, password: user.created_password },
        });
        const { data, error } = self.loginUserMutation;
        if (data && !error) {
          return getSnapshot(data);
        }
        return;
      }
    },
    register: flow(function* () {
      const { data, error } = yield self.registerUserMutation.mutate({
        request: null,
      });
      self.isWasAutoRegisteredNow = true;
      if (!error) {
        return getSnapshot(data);
      }
    }),
  }))
  .actions((self) => ({
    afterCreate() {
      onMutate(self.registerUserMutation, () => {
        self.isWasRegisteredNow = true;
      });
    },

    autoLogin: flow(function* () {
      const user =
        self.restoreUser() ||
        (yield self.loginTg()) ||
        (yield self.login()) ||
        (yield self.register());

      if (user) {
        setUser(user);
        self.currentUser = user;
      }
    }),
  }));
