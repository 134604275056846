import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

export const Instruction = types.model('Instruction', {
  id: types.identifier,
  name: types.string,
  image: types.maybeNull(types.string),
  order: types.number,
  description: types.string,
  recipe: types.string,
});

export type InstructionInstance = Instance<typeof Instruction>;
export type InstrictionSnapshotIn = SnapshotIn<typeof Instruction>;
export type InstructionSnapshotOut = SnapshotOut<typeof Instruction>;

export const InstructionsFilter = types.model({
  recipe: types.maybe(types.string),
});

export type InstructionsFilterInstance = Instance<typeof InstructionsFilter>;
export type InstructionsFilterSnapshotIn = SnapshotIn<
  typeof InstructionsFilter
>;
export type InstructionsFilterSnapshotOut = SnapshotOut<
  typeof InstructionsFilter
>;
