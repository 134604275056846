import {
  StatisticsFiltersSnapshotOut,
  StatisticsSnapshotIn,
} from 'stores/models';
import { buildApiUrl, makeRequest } from '../base';

export const loadNutritionStatistics = (
  filters: StatisticsFiltersSnapshotOut,
  options?: any,
): Promise<StatisticsSnapshotIn> =>
  makeRequest(buildApiUrl('/nutrition_statistics', filters), options);
