import { types } from 'mobx-state-tree';
import { createModelStore, createQuery } from 'mst-query';
import { loadInstructions } from 'stores/api';
import { Instruction, InstructionsFilter } from 'stores/models';
import { createPaginated } from 'stores/queries';

export const InstructionsQuery = createQuery('InstructionsQuery', {
  data: createPaginated(types.reference(Instruction)),
  request: InstructionsFilter,
  endpoint({ request, signal }) {
    return loadInstructions(request, { signal });
  },
});

export const InstructionStore = createModelStore(
  'InstructionStore',
  Instruction,
).props({
  instructionsQuery: types.optional(InstructionsQuery, {}),
});
