import {
  format,
  formatDuration,
  intervalToDuration,
  isSameDay,
  isToday,
  isYesterday,
  subDays,
} from 'date-fns';

export const formatNutritionValue = (value: number) => value.toFixed(0);
export const formatSeconds = (seconds: number) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  return formatDuration(duration) || '0';
};
export const formatDateDay = (value: string | Date) =>
  format(new Date(value), 'dd.MM.yyyy');

export const formatDateDayWithTimeName = (value: string | Date) => {
  if (isToday(value)) return 'Сегодня';
  if (isYesterday(value)) return 'Вчера';
  if (isSameDay(subDays(new Date(), 2), value)) return 'Позавчера';
  return formatDateDay(value);
};
