import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import { MuiFormikField } from './MuiFormikField';
import { Ref } from 'react';
import * as yup from 'yup';

const causeFormSchema = yup.object({
  cause: yup.string().oneOf(['sold', 'other']).required(),
  sold_price: yup
    .number()
    .min(1)
    .when('cause', {
      is: 'sold',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  archive_text: yup.string().when('cause', {
    is: 'other',
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
});

// type CauseSchemaType = yup.InferType<typeof causeFormSchema>;

export interface CauseData {
  cause: 'sold' | 'other';
  sold_price: number | null;
  archive_text: string | null;
}

export const CauseForm: React.FC<{ innerRef: Ref<FormikProps<CauseData>> }> = ({
  innerRef,
}) => (
  <Formik
    innerRef={innerRef}
    initialValues={{
      cause: 'sold',
      sold_price: null,
      archive_text: null,
    }}
    validationSchema={causeFormSchema}
    onSubmit={() => {}}
    initialTouched={{ archive_text: true, cause: true, sold_price: true }}
  >
    <Form>
      <Stack spacing={2}>
        <MuiFormikField name="cause">
          {(props: any) => (
            <FormControl>
              <FormLabel>
                По какой причине Вы хотите снять с публикации объявление?
              </FormLabel>
              <RadioGroup {...props}>
                <FormControlLabel
                  control={<Radio />}
                  value="sold"
                  label="Продано"
                />
                <FormControlLabel
                  control={<Radio />}
                  value="other"
                  label="Другая причина"
                />
              </RadioGroup>
            </FormControl>
          )}
        </MuiFormikField>
        <Field name="cause">
          {({ meta: { value: cause } }: FieldProps<CauseData['cause']>) => (
            <>
              <MuiFormikField name="sold_price">
                {(props: any) =>
                  cause === 'sold' && (
                    <TextField
                      {...props}
                      type="number"
                      label="Цена продажи"
                      placeholder="Введите цену продажи"
                    />
                  )
                }
              </MuiFormikField>
              <MuiFormikField name="archive_text">
                {(props: any) =>
                  cause === 'other' && (
                    <TextField
                      {...props}
                      label="Причина снятия объявления с публикации"
                      placeholder="Введите причину снятия объявления с публикации"
                    />
                  )
                }
              </MuiFormikField>
            </>
          )}
        </Field>
      </Stack>
    </Form>
  </Formik>
);
