import {
  Card,
  CardContent,
  CardHeader,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';
import React from 'react';
import { MuiFormikField } from './MuiFormikField';
import {
  NutritionFields,
  NutritionFieldsContainer,
  nutritionSchema,
} from './NutritionFields';
import { BarcodeReaderButton } from '../dialogs/BarcodeDialog';
import { ImageField } from './Fields';
import { CategoryField, UnitField } from '../dictionaries';
import { isValidEAN } from 'utils';

export const productFormSchema = yup.object({
  id: yup.string().optional(),
  name: yup.string().min(1).required('Необходимо ввести наименование'),
  category: yup
    .array()
    .min(1, 'Необходимо выбрать категорию')
    .required('Необходимо выбрать категорию'),
  nutrition: nutritionSchema,
  unit: yup
    .array()
    .min(1, 'Необходимо выбрать единицу измерения')
    .required('Необходимо выбрать единицу измерения'),
  brand: yup.string().optional(),
  barcode: yup
    .string()
    .optional()
    .test('isValidEAN', 'Штрих-код некорректен', (value) =>
      value ? isValidEAN(value) : true,
    ),
  quantity: yup
    .number()
    .required('Необходимо ввести вес продукта в указанной единице измерения'),
  photo_back: yup
    .string()
    .required('Необходимо фото оборотной стороны продукта'),
  photo_front: yup
    .string()
    .required('Необходимо фото передней стороны продукта'),
});

export type ProductFormSchemaType = yup.InferType<typeof productFormSchema>;

export const recipeFormSchema = productFormSchema.concat(
  yup.object({
    ingredients: yup
      .array(productFormSchema)
      .min(1, 'Выберите хотя бы один игнредиент'),
    cooking_time_seconds: yup.number().min(0).default(0),
    instructions: yup.array(productFormSchema).min(0),
  }),
);

const BarcodeField: React.FC<TextFieldProps> = (props) => (
  <TextField
    label="Штрих-код"
    placeholder="Введите штрих-код продукта"
    {...props}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <BarcodeReaderButton
            onCapture={(barcode) =>
              props.onChange?.({
                target: { name: props.name, value: barcode },
              } as any)
            }
          />
        </InputAdornment>
      ),
      ...props.InputProps,
    }}
  />
);

const fieldProps = {
  variant: 'outlined',
  fullWidth: true,
};

export const ProductFields: React.FC = observer(() => (
  <>
    <MuiFormikField name="barcode">
      {(props: any) => (
        <BarcodeField {...props} {...fieldProps} type="number" autoFocus />
      )}
    </MuiFormikField>
    <MuiFormikField type="image" name="photo_front">
      {(props: any) => (
        <ImageField
          {...props}
          label="Фото продукта с лицевой стороны"
          labelPlacement="top"
          required
        />
      )}
    </MuiFormikField>
    <MuiFormikField type="image" name="photo_back">
      {(props: any) => (
        <ImageField
          {...props}
          label="Фото продукта с оборотной стороны с указанием БЖУ"
          labelPlacement="top"
          required
        />
      )}
    </MuiFormikField>
    <MuiFormikField name="name">
      {(props: any) => (
        <TextField
          {...props}
          {...fieldProps}
          type="text"
          label="Название"
          required
        />
      )}
    </MuiFormikField>
    <MuiFormikField name="category" type="dict">
      {(props: any) => (
        <CategoryField
          fieldProps={props.props}
          {...props}
          {...fieldProps}
          required
        />
      )}
    </MuiFormikField>
    <MuiFormikField name="unit" type="dict">
      {(props: any) => (
        <UnitField
          fieldProps={props.props}
          {...props}
          {...fieldProps}
          required
        />
      )}
    </MuiFormikField>
    <MuiFormikField name="quantity">
      {(props: any) => (
        <TextField
          label="Вес продукта"
          placeholder="Введите вес продукта"
          type="number"
          required
          {...props}
          {...fieldProps}
        />
      )}
    </MuiFormikField>
    <MuiFormikField name="brand">
      {(props: any) => (
        <TextField
          label="Название бренда"
          placeholder="Название бренда"
          {...props}
          {...fieldProps}
        />
      )}
    </MuiFormikField>
    <Card>
      <CardHeader title="Состав БЖУ на 100 грамм" />
      <CardContent>
        <NutritionFieldsContainer>
          <NutritionFields name="nutrition" required />
        </NutritionFieldsContainer>
      </CardContent>
    </Card>
  </>
));

export const RecipeFields: React.FC = observer(() => (
  <>
    <ProductFields />
  </>
));
