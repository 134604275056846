import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

export const TelegramUser = types.model({
  first_name: types.optional(types.string, ''),
  last_name: types.optional(types.string, ''),
  username: types.optional(types.string, ''),
});

export const User = types.model('User', {
  auth_token: types.string,
  first_name: types.optional(types.string, ''),
  last_name: types.optional(types.string, ''),
  password: types.optional(types.string, ''),
  username: types.optional(types.string, ''),
  created_password: types.optional(types.string, ''),
  tg_user: types.maybe(types.maybeNull(TelegramUser)),
});

export type UserInstance = Instance<typeof User>;
export type UserSnapshotIn = SnapshotIn<typeof User>;
export type UserSnapshotOut = SnapshotOut<typeof User>;

export const LoginRequest = types.model('LoginRequest', {
  password: types.string,
  username: types.string,
});

export type LoginRequestInstance = Instance<typeof LoginRequest>;
export type LoginRequestSnapshotIn = SnapshotIn<typeof LoginRequest>;
export type LoginRequestSnapshotOut = SnapshotOut<typeof LoginRequest>;

export const TgLoginRequest = types.model('TgLoginRequest', {
  tg_init_data_raw: types.string,
});

export type TgLoginRequestRequestInstance = Instance<typeof TgLoginRequest>;
export type TgLoginRequestRequestSnapshotIn = SnapshotIn<typeof TgLoginRequest>;
export type TgLoginRequestRequestSnapshotOut = SnapshotOut<
  typeof TgLoginRequest
>;
