import {
  Button,
  ButtonProps,
  Dialog,
  DialogProps,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { PhotoWebcam, PhotoWebcamProps } from './Camera';
import { useBoolean } from 'hooks';
import { PhotoCamera } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { useCallback } from 'react';

export const PhotoDialog: React.FC<
  Omit<DialogProps, 'onClose'> &
    Pick<PhotoWebcamProps, 'onCapture'> & { onClose: () => void }
> = ({ onCapture, ...props }) => (
  <Dialog {...props}>
    <DialogTitle sx={{ mr: 3 }}>Фото</DialogTitle>
    <IconButton
      aria-label="close"
      onClick={props.onClose}
      sx={(theme) => ({
        position: 'absolute',
        right: 8,
        top: 8,
        color: theme.palette.grey[500],
      })}
    >
      <CloseIcon />
    </IconButton>
    <PhotoWebcam onCapture={onCapture} />
  </Dialog>
);

export const PhotoDialogButton: React.FC<
  ButtonProps & Pick<PhotoWebcamProps, 'onCapture'>
> = ({ onCapture, ...props }) => {
  const [isOpen, open, close] = useBoolean();
  const handleCapture = useCallback(
    (image: string) => {
      close();
      return onCapture(image);
    },
    [close, onCapture],
  );
  return (
    <>
      <Button startIcon={<PhotoCamera />} {...props} onClick={open}>
        {props.children || 'Сделать фото'}
      </Button>
      <PhotoDialog
        open={isOpen}
        onCapture={handleCapture}
        onClose={close}
        fullScreen
      />
    </>
  );
};
