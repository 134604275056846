import {
  LoginRequestSnapshotIn,
  TgLoginRequestRequestSnapshotIn,
  UserInstance,
} from 'stores/models';
import { buildApiUrl, makeRequest } from '../base';

export const login = (
  credentials: LoginRequestSnapshotIn,
): Promise<UserInstance> =>
  makeRequest(buildApiUrl('/login_user'), {
    method: 'POST',
    body: JSON.stringify(credentials),
  });

export const loginTgUser = (
  credentials: TgLoginRequestRequestSnapshotIn,
): Promise<UserInstance> =>
  makeRequest(buildApiUrl('/login_tg_user'), {
    method: 'POST',
    body: JSON.stringify(credentials),
  });

export const register = (): Promise<UserInstance> =>
  makeRequest(buildApiUrl('/register'), {
    method: 'POST',
    body: JSON.stringify({}),
  });
