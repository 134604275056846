import { types, getSnapshot } from 'mobx-state-tree';
import { createModelStore, createMutation, createQuery } from 'mst-query';
import { saveMealTime, loadMealTime, removeMealTime } from 'stores/api';
import {
  guardMealTimeName,
  MealTime,
  MealTimeNames,
  SaveMealTime,
} from 'stores/models';

export const MealTimeQuery = createQuery('MealTimeQuery', {
  data: types.reference(MealTime),
  request: types.model({ id: types.string, name: types.maybe(MealTimeNames) }),
  endpoint({ request, signal }) {
    if (request.id === 'new') {
      return Promise.resolve({
        id: request.id,
        name: guardMealTimeName(request.name),
        count: 1,
      });
    }
    return loadMealTime(request.id, { signal });
  },
});

export const SaveMealTimeMutation = createMutation('SaveMealTimeMutation', {
  data: types.safeReference(MealTime),
  request: SaveMealTime,
  endpoint({ request }) {
    return saveMealTime(getSnapshot(request));
  },
});

export const RemoveMealTimeMutation = createMutation('RemoveMealTimeMutation', {
  data: types.safeReference(MealTime),
  request: types.string,
  endpoint({ request }) {
    return removeMealTime(request);
  },
});

export const MealTimeStore = createModelStore('MealTimeStore', MealTime).props({
  saveMealTimeMutation: types.optional(SaveMealTimeMutation, {}),
  removeMealTimeMutation: types.optional(RemoveMealTimeMutation, {}),
  mealTimeQuery: types.optional(MealTimeQuery, {}),
});
