import { destroy, Instance, types } from 'mobx-state-tree';
import {
  CategoryStore,
  DiaryStore,
  DictionariesStore,
  InstructionStore,
  MealTimeStore,
  NutritionStore,
  ProductStore,
  RecipeStore,
  StatisticsStore,
  UnitStore,
  UserStore,
} from 'stores/queries';
import { createRootStore, onMutate, QueryClient } from 'mst-query';

export const RootStore = createRootStore({
  dictionariesStore: types.optional(DictionariesStore, {}),
  categoryStore: types.optional(CategoryStore, {}),
  unitStore: types.optional(UnitStore, {}),
  diaryStore: types.optional(DiaryStore, {}),
  mealTimeStore: types.optional(MealTimeStore, {}),
  productStore: types.optional(ProductStore, {}),
  userStore: types.optional(UserStore, {}),
  recipeStore: types.optional(RecipeStore, {}),
  instructionStore: types.optional(InstructionStore, {}),
  statisticsStore: types.optional(StatisticsStore, {}),
  nutritionsStore: types.optional(NutritionStore, {}),
}).actions((self) => ({
  async afterCreate() {
    self.dictionariesStore.dictionariesQuery.refetch();

    onMutate(self.mealTimeStore.removeMealTimeMutation, (data) => {
      if (data) {
        self.diaryStore.diaryQuery.data?.mealtimes.remove(data);
        destroy(data);
      }
    });
  },
}));

export type RootStoreInstance = Instance<typeof RootStore>;
export const queryClient = new QueryClient({ RootStore });
