import {
  MealTimeSnapshotIn,
  SaveMealTimeSnapshotIn,
  SaveMealTimeSnapshotOut,
} from 'stores/models';
import {
  buildApiUrl,
  makeCreateUpdateRequest,
  makeRemoveRequest,
  makeRequest,
} from '../base';

const MEALTIMES_PATH = '/mealtimes';

// Предполагается, что Diary всегда создаётся, даже если отсутствует diary_date (на текущую дату, если это так)
export const loadMealTime = (
  id: string,
  options?: any,
): Promise<MealTimeSnapshotIn> =>
  makeRequest(buildApiUrl(`${MEALTIMES_PATH}/${id}`), options);

export const saveMealTime = makeCreateUpdateRequest<
  SaveMealTimeSnapshotIn,
  SaveMealTimeSnapshotOut
>(MEALTIMES_PATH);

export const removeMealTime = makeRemoveRequest(MEALTIMES_PATH);
