import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import paths from 'src/paths';
import ErrorPage from 'src/pages/ErrorPage';
import { lazy } from 'react';
import PagesContainer from './PagesContainer';
import RootProtector from './RootProtector';

const router = createBrowserRouter(
  [
    {
      path: paths.root.pattern,
      element: (
        <>
          <RootProtector />
          <PagesContainer />
        </>
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          path: paths.auth.pattern,
          Component: lazy(() => import('../pages/AuthPage')),
        },
        {
          path: paths.diaryDetail.pattern,
          Component: lazy(() => import('../pages/DiaryPage')),
        },
        {
          path: paths.recipeReportBug.pattern,
          Component: lazy(() => import('../pages/RecipeReportBug')),
        },
        {
          path: paths.recipies.pattern,
          Component: lazy(() => import('../pages/RecipiesPage')),
        },
        {
          path: paths.recipeNew.pattern,
          Component: lazy(() => import('../pages/RecipeNewPage')),
        },
        {
          path: paths.recipe.pattern,
          Component: lazy(() => import('../pages/RecipePage')),
        },
        {
          path: paths.mealTimeEdit.pattern,
          Component: lazy(() => import('../pages/MealTimePage')),
        },
        {
          path: paths.profile.pattern,
          Component: lazy(() => import('../pages/ProfilePage')),
        },
        {
          path: paths.statistics.pattern,
          Component: lazy(() => import('../pages/StatisticsPage')),
        },
        {
          path: '/*',
          element: <Navigate to={paths.root({})} />,
        },
      ],
    },
  ],
  {
    basename: process.env.BASE_URL,
  },
);

export const AppRouter = () => <RouterProvider router={router} />;
