import { types } from 'mobx-state-tree';
import { createModelStore, createQuery } from 'mst-query';
import { getDictionaries } from 'stores/api/requests/dictionaries';
import { Dictionaries } from 'stores/models';

export const DictionariesQuery = createQuery('DictionariesQuery', {
  data: Dictionaries,
  // request: types.model({}),
  endpoint({ signal }) {
    return getDictionaries({ signal });
  },
});

export const DictionariesStore = createModelStore(
  'DictionariesStore',
  DictionariesQuery,
).props({
  dictionariesQuery: types.optional(DictionariesQuery, {}),
});
