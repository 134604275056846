import { Button, Container, Drawer, DrawerProps } from '@mui/material';
import { BottomAppBar } from '../shell';
import { memo } from 'react';

export const FiltersDrawer: React.FC<DrawerProps> = memo(
  ({ children, ...props }) => (
    <Drawer anchor="right" {...props}>
      <Container sx={{ height: '100vh' }}>{children}</Container>
      <BottomAppBar color="transparent">
        <Button type="submit" fullWidth>
          Применить
        </Button>
      </BottomAppBar>
    </Drawer>
  ),
);
