import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import paths from 'src/paths';
import { getUTCDate } from 'stores';
import {
  getUser,
  registerInterceptor,
  setToken,
  unregisterInterceptor,
} from 'stores/api';

const RootProtector = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const interceptor = (response: any, data: any) => {
      if (response.status === 401 && data?.detail?.includes('Invalid token')) {
        setToken(null);
      }
    };
    registerInterceptor(interceptor);
    return () => {
      unregisterInterceptor(interceptor);
    };
  }, [navigate]);
  useEffect(() => {
    if (!getUser()?.auth_token) {
      return navigate(paths.auth({}));
    }
    if (location.pathname === paths.root({})) {
      return navigate(
        paths.diaryDetail({ diary_date: getUTCDate(new Date()) }),
      );
    }
  }, [location.pathname, navigate]);

  return null;
});

RootProtector.displayName = 'RootProtector';

export default RootProtector;
