import { PropsWithChildren } from 'react';
import { queryClient } from './RootStore';
import React from 'react';
import { createContext } from 'mst-query';

const context = createContext(queryClient);
const { QueryClientProvider } = context;

export const { useRootStore } = context;

export const StoresProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <QueryClientProvider>{children}</QueryClientProvider>
);
