import { getUTCDate } from 'stores/utils';
import { buildApiUrl, makeCreateUpdateRequest, makeRequest } from '../base';
import { DiarySnapshotIn, DiarySnapshotOut } from 'stores/models';

export const getDiary = (
  date: string | Date | null,
  options: any,
): Promise<DiarySnapshotIn> => {
  const dateString = date && typeof date === 'object' ? getUTCDate(date) : date;
  return makeRequest(buildApiUrl(`/diaries/${dateString}`), options);
};

export const saveDiary = makeCreateUpdateRequest<
  DiarySnapshotIn,
  DiarySnapshotOut
>('/diaries', { apiLookupField: 'date' });
