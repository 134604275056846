import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

export const Dictionary = types
  .model('Dictionary', {
    id: types.identifier,
    name: types.string,
    order: types.optional(types.number, 0),
  })
  .preProcessSnapshot((snapshot) => ({
    ...snapshot,
    order: snapshot?.order || 0,
  }));

export type DictionaryInstance = Instance<typeof Dictionary>;
export type DictionarySnapshotIn = SnapshotIn<typeof Dictionary>;
export type DictionarySnapshotOut = SnapshotOut<typeof Dictionary>;

export const sorterByOrder = (
  dict1: DictionaryInstance,
  dict2: DictionaryInstance,
) => dict1.order - dict2.order;
