import { LaunchParams } from '@telegram-apps/sdk';
import { Primitives } from './type';

/**
 * Проверяет, является ли URL абсолютным.
 * @param  url - URL для проверки. По умолчанию пустая строка.
 * @returns  Возвращает true, если URL абсолютный, иначе false.
 */
const isAbsoluteUrl = (url: string = ''): boolean =>
  /^(?:[a-zA-Z][a-zA-Z\d+\-.]*):\/\//.test(url);

/**
 * Преобразует объект query параметров в строковые значения.
 * @param  query - Объект с параметрами запроса.
 * @returns Возвращает объект с параметрами в виде строк.
 */
const stringifyQuery = (query: Record<string, any>): Record<string, string> =>
  Object.fromEntries(
    Object.entries(query).map(([key, value]) => [key, String(value)]),
  );

/**
 * Создаёт полный URL API на основе базового URL и параметров запроса.
 * @param path - Путь к ресурсу API.
 * @param query - Параметры запроса.
 * @returns Полный URL в виде строки.
 */
export const buildApiUrl = (
  path: string,
  query?: string[][] | Record<string, Primitives> | URLSearchParams,
) => {
  const base = isAbsoluteUrl(process.env.API_URL)
    ? process.env.API_URL
    : `${window.location.origin}${process.env.API_URL || ''}`;
  const subpath = `${process.env.BASE_API_URL || ''}${path}/`.replace(
    /\/\//g,
    '/',
  );
  const url = new URL(subpath, base);
  url.search = new URLSearchParams(
    typeof query === 'object' ? stringifyQuery(query) : query,
  ).toString();
  return url.toString();
};

/**
 * Получает токен из локального хранилища.
 * @returns Возвращает токен аутентификации или null, если токена нет.
 */
export const getToken = () => {
  const userStr = localStorage.getItem('user');
  if (!userStr) return null;
  try {
    const user = JSON.parse(userStr);
    return user.auth_token;
  } catch {
    return null;
  }
};

export const getUser = () => {
  const userStr = localStorage.getItem('user');
  if (!userStr) return null;
  try {
    return JSON.parse(userStr);
  } catch (err) {
    return null;
  }
};

export const setUser = (user: { auth_token: string }) => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const setToken = (token: string | null) => {
  const user = getUser();
  if (!user) return;
  user.auth_token = token;
  setUser(user);
};

export const getTelegramLaunchParams = () => {
  const userStr = localStorage.getItem('telegram_launch_params');
  if (!userStr) return null;
  try {
    return JSON.parse(userStr) as LaunchParams;
  } catch (err) {
    return null;
  }
};

export const getTelegramUser = () => {
  const launchParams = getTelegramLaunchParams();
  return launchParams?.initData?.user;
};

export const setTelegramUser = (launchParams: LaunchParams) => {
  localStorage.setItem('telegram_launch_params', JSON.stringify(launchParams));
};

/**
 * Формирует заголовки для запроса.
 * Включает токен аутентификации и CSRF-токен, если они доступны.
 * @returns Объект с заголовками запроса.
 */
export const buildHeaders = () => {
  const token = getToken();
  const csrftoken = new URLSearchParams(document.cookie).get('csrftoken');
  return {
    ...(token ? { Authorization: `Token ${getToken() || ''}` } : {}),
    ...(csrftoken ? { 'X-CSRFToken': csrftoken } : {}),
    'Content-Type': 'application/json',
  };
};
