import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';
import { Product } from './Product';
import { Instruction } from './Instruction';

export const Recipe = types.compose(
  'Recipe',
  Product,
  types.model({
    ingredients: types.optional(types.array(types.reference(Product)), []),
    cooking_time_seconds: types.optional(types.number, 0),
    instructions: types.optional(types.array(types.reference(Instruction)), []),
  }),
);

export type RecipeInstance = Instance<typeof Recipe>;
export type RecipeSnapshotIn = SnapshotIn<typeof Recipe>;
export type RecipeSnapshotOut = SnapshotOut<typeof Recipe>;

export const RecipeFilters = types.model({
  search: types.optional(types.string, ''),
});

export type RecipeFiltersInstance = Instance<typeof RecipeFilters>;
export type RecipeFiltersSnapshotIn = SnapshotIn<typeof RecipeFilters>;
export type RecipeFiltersSnapshotOut = SnapshotOut<typeof RecipeFilters>;
