import { format, isValid } from 'date-fns';
import { IAnyType, typecheck, types } from 'mobx-state-tree';

export const withSetPropAction = (mstInstance: any) => ({
  setProp(field: any, newValue: any) {
    mstInstance[field] = newValue;
  },
});

export const validate = (model: IAnyType, value: any) => {
  try {
    typecheck(model, value);
    return true;
  } catch (e) {
    return false;
  }
};

export const getUTCDate = (date: Date | string | number) => {
  const lDate =
    typeof date === 'string' || typeof date === 'number'
      ? new Date(date)
      : date;
  return format(lDate, 'yyyy-MM-dd');
};

/**
 * Дата принимающий любоай формат с возможностью конвертирования в необходимый тип на выходе
 * @param converter конвертер даты
 * @returns
 */
export const DateConvertable = <DateType extends string | number | Date>(
  converter: (date: Date) => DateType,
) =>
  types.custom<DateType, Date>({
    name: 'DateConvertable',
    fromSnapshot: (snapshot) => new Date(snapshot),
    toSnapshot: (date: Date) => (date ? converter(date) : date),
    isTargetType: (value: any): value is Date => isValid(new Date(value)),
    getValidationMessage: () => 'Значение не является конвертируемым в дату',
  });

export const UTCDate = DateConvertable<string>(getUTCDate);

export const processMinNumber =
  (key: string, minNumber: number, otherProcessor?: any) =>
  (snapshot: any) => ({
    ...snapshot,
    ...(otherProcessor ? otherProcessor(snapshot) : {}),
    [key]: (snapshot?.[key] || 0) < minNumber ? minNumber : snapshot?.[key],
  });

export const concatFormikNames = (...names: (string | null | undefined)[]) =>
  names.filter(Boolean).join('.');
