import {
  SaveNutritionSnapshotIn,
  SaveNutritionSnapshotOut,
} from 'stores/models';
import { makeCreateUpdateRequest } from '../base';

const NUTRITIONS_PATH = '/nutritions';

export const saveNutrition = makeCreateUpdateRequest<
  SaveNutritionSnapshotIn,
  SaveNutritionSnapshotOut
>(NUTRITIONS_PATH);
