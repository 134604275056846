import { Container } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from './Header';
import { Footer } from './Footer';
import { FullPageLoader } from 'src/components';

const PageContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <Container
    component="main"
    sx={{
      height: '100vh',
      // 56px и 60.5px - размеры плавающих хедера и футера
      maxHeight: 'calc(100vh - 64px - 64px)',
      overflowY: 'auto',
      margin: 'auto',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      overscrollBehavior: 'none',
    }}
  >
    {children}
  </Container>
);

const PagesContainer = observer(() => (
  <Container sx={{ p: 0 }}>
    <Header />
    <PageContainer>
      <Suspense fallback={<FullPageLoader />}>
        <Outlet />
      </Suspense>
    </PageContainer>
    <Footer />
  </Container>
));

PagesContainer.displayName = 'PagesContainer';

export default PagesContainer;
