import { useFormikContext } from 'formik';
import {
  calculateCalories,
  concatFormikNames,
  NutritionInstance,
} from 'stores';
import * as yup from 'yup';
import { MuiFormikField } from './MuiFormikField';
import { Box, TextField } from '@mui/material';
import React, { PropsWithChildren } from 'react';

export const nutritionSchema = yup.object({
  protein: yup
    .number()
    .min(0, 'Количество белков должно быть больше или равно нулю')
    .required('Необходимо заполнить количество белков'),
  fat: yup
    .number()
    .min(0, 'Количество жиров должно быть больше или равно нулю')
    .required('Необходимо заполнить количество жиров'),
  carbohydrates: yup
    .number()
    .min(0, 'Количество углеводов должно быть больше или равно нулю')
    .required('Необходимо заполнить количество углеводов'),
});

const CaloriesField: React.FC<{ name?: string }> = ({ name }) => {
  const { values } = useFormikContext<NutritionInstance | any>();
  const calories = calculateCalories(name ? (values as any)[name] : values);
  return (
    <MuiFormikField name={concatFormikNames(name, 'calories')}>
      {(props: any) => (
        <TextField
          type="number"
          label="Калории"
          disabled
          {...props}
          value={calories}
        />
      )}
    </MuiFormikField>
  );
};

export const NutritionFields: React.FC<{
  name?: string;
  required?: boolean;
}> = ({ name, required }) => (
  <>
    <CaloriesField name={name} />
    <MuiFormikField name={concatFormikNames(name, 'protein')}>
      {(props: any) => (
        <TextField type="number" label="Белки" required={required} {...props} />
      )}
    </MuiFormikField>
    <MuiFormikField name={concatFormikNames(name, 'fat')}>
      {(props: any) => (
        <TextField type="number" label="Жиры" required={required} {...props} />
      )}
    </MuiFormikField>
    <MuiFormikField name={concatFormikNames(name, 'carbohydrates')}>
      {(props: any) => (
        <TextField
          type="number"
          label="Углеводы"
          required={required}
          {...props}
        />
      )}
    </MuiFormikField>
  </>
);

export const NutritionFieldsContainer: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(2,auto)',
      gap: 1,
    }}
  >
    {children}
  </Box>
);
