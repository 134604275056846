import { TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { MuiFormikField } from 'src/components';

export const RecipiesFilterFields = observer(() => (
  <>
    <MuiFormikField name="name">
      {(props: any) => (
        <TextField
          {...props}
          type="text"
          label="Наименование"
          variant="standard"
          fullWidth
        />
      )}
    </MuiFormikField>
  </>
));
