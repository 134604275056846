import { PropsWithChildren, useEffect } from 'react';
import './App.css';
import { ru } from 'date-fns/locale';

import { StoresProvider } from 'stores';
import { AppRouter } from 'src/router/Routes';
import { AppTheme } from './Theme';
import { CssBaseline, IconButton } from '@mui/material';
import { setDefaultOptions } from 'date-fns';
import { ShellProvider } from './components';
import { closeSnackbar, SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ruRU } from '@mui/x-date-pickers/locales';
import CloseIcon from '@mui/icons-material/Close';

export const Providers: React.FC<PropsWithChildren> = ({ children }) => (
  <ShellProvider>
    <StoresProvider>
      <AppTheme>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          localeText={
            ruRU.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <SnackbarProvider
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={3000}
            action={(snackbarId) => (
              <IconButton onClick={() => closeSnackbar(snackbarId)}>
                <CloseIcon />
              </IconButton>
            )}
          >
            {children}
          </SnackbarProvider>
        </LocalizationProvider>
      </AppTheme>
    </StoresProvider>
  </ShellProvider>
);

const App = () => {
  useEffect(() => {
    setDefaultOptions({ locale: ru });
  }, []);
  return (
    <Providers>
      <CssBaseline />
      <AppRouter />
    </Providers>
  );
};

export default App;
