import { Container, Typography } from '@mui/material';
import { useRouteError } from 'react-router-dom';

const ErrorPage: React.FC = () => {
  const error: any = useRouteError();

  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
      <Typography variant="h1">Упс!</Typography>
      <Typography variant="h5">
        Извините, произошла неожиданная ошибка
      </Typography>
      <Typography variant="caption">
        {error.statusText || error.message}
      </Typography>
    </Container>
  );
};

export default ErrorPage;
