import { getSnapshot, types } from 'mobx-state-tree';
import { createModelStore, createQuery, createMutation } from 'mst-query';
import {
  createPaginated,
  createPaginatedElement,
  PaginationFilters,
} from 'stores/queries';
import {
  loadProducts,
  loadProductsByBarcode,
  reportProductBug,
  saveProduct,
} from 'stores/api';
import {
  Product,
  ProductsFilters,
  ProductSnapshotOut,
  ReportProductBug,
} from 'stores/models';
import { isBarCodeText, removeSpaces } from 'utils';

export const ProductsQuery = createQuery('ProductsQuery', {
  data: createPaginated(types.reference(Product)),
  request: types.compose(ProductsFilters, PaginationFilters),
  endpoint({ signal, request, setData }) {
    if (isBarCodeText(request.search)) {
      return createPaginatedElement(
        loadProductsByBarcode(removeSpaces(request.search)!),
      );
    }
    const promise = loadProducts(
      { ...request, load_external_data: false },
      { signal },
    );
    loadProducts({ ...request, load_external_data: true }, { signal }).then(
      (data) => {
        setData(data);
      },
    );
    return promise;
  },
});

export const CreateProductMutation = createMutation('CreateProductMutation', {
  data: types.safeReference(Product),
  request: types.union(types.reference(Product), Product),
  endpoint({ request }) {
    return saveProduct(getSnapshot(request) as ProductSnapshotOut);
  },
});

export const ReportProductBugMutation = createMutation(
  'ReportProductBugMutation',
  {
    request: ReportProductBug,
    endpoint({ request }) {
      return reportProductBug(getSnapshot(request));
    },
  },
);

export const ProductStore = createModelStore('ProductStore', Product).props({
  productsQuery: types.optional(ProductsQuery, {}),
  createProductMutation: types.optional(CreateProductMutation, {}),
  reportProductBugMutation: types.optional(ReportProductBugMutation, {}),
});
